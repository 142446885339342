import React, { useState } from 'react';
import {
  Drawer,
 } from '@material-ui/core';
 import {
   Theme,
   makeStyles,
   createStyles,
 } from '@material-ui/core/styles';
 import { 
  Resume,
  Appearance,
  Bio,
  Connections,
  PowerStats,
  Work,
  } from './Profile';

 const useStyles = makeStyles((theme: Theme) => 
   createStyles({
     root: {
       '&> .MuiDrawer-paper': {
         width: '95vw',
         background: 'var(--dark-gray)',
         padding: '1.8rem',
       },
       '&> .MuiDrawer-paper > .MuiCard-root': {
         overflow: 'auto',
         minHeight: '100vh',
         position: 'relative',
         background: 'transparent',
         color: 'var(--terminal-main-color)',
       },
     },
     avatarLarge: {
       width: theme.spacing(10),
       height: theme.spacing(10),
     },
     srOnly: {
       visibility: 'hidden',
       display: 'inline-block',
       overflow: 'hidden',
       width: '1px',
       height: '1px',
     }
   })
 );

 
 const Hero = (props) => {
   const classes = useStyles();
   
   const [activeTab, setActiveTab] = useState(0);

  const stateCharacter = {
    visible: props.stateCharacter,
  };

  const { Character } = props;

  const hideCharacter = () => {
    setActiveTab(0);
    props.hideCharacter();
  }

  const heroTabs:Array<Object> = [
    { label: 'Appearance',
      component: <Appearance Character={Character} />,
    },
    { label: 'Bio',
      component: <Bio Character={Character} />,
    },
    { label: 'Power stats',
      component: <PowerStats Character={Character} />,
    },
    { label: 'Connections',
      component: <Connections Character={Character} />,
    },
    { label: 'Work',
      component: <Work Character={Character} />,
    },
  ];

  const handleTabsChange = (event:any, tabIndex:number) => {
    setActiveTab(tabIndex);
  }

  const CharacterHero = () => {
    if (props.stateCharacter && props.Character) {
      return (<Drawer
        variant="permanent"
        className={classes.root}
        open={stateCharacter.visible}
        anchor='right'>
          <Resume 
            Character={Character}
            heroTabs={heroTabs}
            activeTab={activeTab}
            handleTabsChange={handleTabsChange}
            hideCharacter={hideCharacter} />
      </Drawer>);
    } else {
      return (<div> </div>);
    }
  }

  return (
    <>
      <CharacterHero />
    </>
  )
}

export default Hero