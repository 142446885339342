import React from 'react';
import CardProfile from '../../CardProfile';

export default (props:any) => {
  const { Character } =  props;
  
  return (
    <>      
      <CardProfile label='Gender'>
        {Character.appearance['gender']}
      </CardProfile>
      <CardProfile label='Eye'>
        {Character.appearance['eye-color']}
      </CardProfile>
      <CardProfile label='Hair'>
        {Character.appearance['hair-color']}
      </CardProfile>
      <CardProfile label='Height'>
        {Character.appearance['height'].join('/')}
      </CardProfile>
      <CardProfile label='Weight'>
        {Character.appearance['weight'].join('/')}
      </CardProfile>
      <CardProfile label='Race'>
        {Character.appearance['race']}
      </CardProfile>
    </>
  )
};