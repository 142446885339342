
import { AppBar } from '@material-ui/core';
import styled from 'styled-components';

export default styled(AppBar)`
&& {
    background: transparent;
    font-family: monospace;
    text-transform: uppercase;
    color: var(--terminal-main-color);
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    margin-bottom: 1.5rem;
    border-bottom: 1px dashed var(--terminal-main-color);
    text-align: center;
}`;