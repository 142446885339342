import React from 'react';
import CardProfile from '../../CardProfile';

export default (props:any) => {
  const { Character } =  props;

  return (
    <>      
      <CardProfile label="Groups Affiliations">
        {Character.connections['group-affiliation']}
      </CardProfile>
      <CardProfile label="Relatives">
        {Character.connections['relatives']}
      </CardProfile>
    </>
  )
}
