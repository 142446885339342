import React, { useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import './SearchBar.css';


export default (props:any) => {
	const [name, setName] = useState(props.name || '');

	const getCharacters = (e:any) => {
		e.preventDefault();
		if (e.keyCode === 13 && name) {
			props.getCharacters(name);
		}
	}

	const clearSearch = (e:any) => {
		e.preventDefault();
		props.clearSearch();
		setName('');
	}

	const setSearch = (searchName:string) => {
		setName(searchName);
	}
	return (
		<div className="searchBar">
			<div className="searchBox">
					<input
						type="text"
						value={name}
						placeholder="Type the character name and hit <Enter>"
						onChange={(e:any) => setSearch(e.target.value)}
						onKeyUp={(e:any) => getCharacters(e)} />
					<button
						className="resetSearch"
						onClick={(e:any) => clearSearch(e)}>
						<ClearIcon />
					</button>
			</div>
		</div>
	)
};