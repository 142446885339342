import React from 'react';
import {
  Box, 
} from '@material-ui/core';

export default (props:any) => {
  const {
    components,
    activeTab,
  } = props;
  return (
    <>
      {components.map((component, index) => {
        return (
          <div
            key={`hero-tabpanel-${index}`}
            role="tabpanel"
            hidden={activeTab !== index}
            id={`hero-tabpanel-${index}`}
            aria-labelledby={`hero-tab-${index}`}>
              <Box p={3}>
                {component.component}
              </Box>
            </div>
        );
      })
      }
    </>
  );
}