
import React, {useState, useMemo } from 'react';

import { Box, Container, Backdrop, CircularProgress } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Layout from './layout';
import Header from './layout/Header';
import { All, Hero } from './Components/Characters';
import SearchBar from './Components/SearchBar';
import CharactersService from './Service/CharactersService';


const  Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};


const ResponseMessage = (props) => {
  if (props.isError) {
    return (<>
      <Alert onClose={props.handleClose} severity={'error'}>
          {props.responseMessage}
      </Alert>
    </>);
  }
  return null;
};

const App = () => {
  const [searchName, setSearchName] = useState('');
  const [Characters, setCharacters] = useState([]);
  const [Character, setCharacter] = useState({});
  const [stateCharacter, setStateCharacter] = useState(false);
  const [responseMessage, setResponseMessage] = useState('No records');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  
  const showCharacter = (Character) => {
    if (Character) {
      setCharacter(Character);
      setStateCharacter(true);
    }
  }

  const hideCharacter = () => {
    setCharacter({});
    setStateCharacter(false);
  }

  const toggleCharacter = (state) => {
    setStateCharacter(state);
  }

  const handleClose = (e?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return 
    }
    setIsError(false);
  }

  const memoResponseError = useMemo(() => {
    return (<ResponseMessage
      isError={isError}
      handleClose={handleClose}
      responseMessage={responseMessage} />)
  }, [isError, responseMessage]);

  const memoCharacters = useMemo(() => {
    if (loading) {
      return (<></>)
    }
      return (<All
        Characters={Characters}
        showCharacter={showCharacter}
        responseMessage={responseMessage}
        />);
}, [Characters, responseMessage, loading]);

  const getCharacters = async (name:string) => {
    setSearchName(name);
      if (name) {      
        setCharacters([]);
        setIsError(false);
        setResponseMessage('');
        setLoading(true);
        CharactersService.getByName(name).then(async res => {
          setLoading(false);
          if (res.response === 'success') {
            setIsError(false);
            setCharacters(res.results);
            setResponseMessage(res.response);
          } else {
            setIsError(true);
            setCharacters([]);
            setResponseMessage(res.message);
          }
        });
      };
  }

  
  const clearSearch = () => {
    setCharacters([]);
    setIsError(false);
    setResponseMessage('');
    setSearchName('');
  }

  return (
    <Layout>
      <Header siteName="Comics Characters Guide" />
      <Container>
        <Box>
          {memoResponseError}
          {memoCharacters}
        </Box>
      </Container>
      <Hero Character={Character} stateCharacter={stateCharacter} toggleCharacter={toggleCharacter} hideCharacter={hideCharacter} />
      <SearchBar getCharacters={getCharacters} clearSearch={clearSearch} name={searchName}/>
      <Backdrop open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Layout>
  )
};

export default App;