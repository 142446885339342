import React from 'react';
import { Typography } from '@material-ui/core';
import AppBar from './Header';
// import { Menu } from '@material-ui/icons';

function Header(props) {
  return (
    <AppBar position="static">
      <Typography variant="h4">
        { props.siteName }
      </Typography>
    </AppBar>
  )
}

export default Header