import React from 'react';
import {
  Grid,
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import PageViewIcon from '@material-ui/icons/Pageview';
// eslint-disable-next-line
import Character from '../../Interfaces/Character';

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    gridContainer: {
      marginBottom: '100px',
    },
    root: {
      height: '100%',
      display: 'flex',
      borderRadius: '10px',
      backgroundColor: 'var(--dark)',
      border: '1px solid var(--terminal-main-color)',
      transition: 'all ease 0.5s',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '2px 2px 6px rgba(0,0,0,0.5)',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: 'auto',
      color: 'var(--terminal-main-color)',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      flex: '0 1 40%',
    },
    coverImg: {
      objectFit: 'cover',
      width: 'auto',
      maxWidth: '100%',
      minHeight: '100%'
    },
    viewCharacter: {
      marginTop: '1.5rem',
      background: 'var(--terminal-main-color)',
      '&:hover': {
        background: 'var(--dark-gray)',
        color: 'var(--terminal-main-color)',
      },
    }
  }));

const All = (props) => {
  const classes = useStyles();

  const showCharacter = (Character) => {
    props.showCharacter(Character);
  }
  
  if(props.Characters.length > 0 ) {
    return (
    <div>
      <Grid container spacing={3} className={classes.gridContainer}>
        { props.Characters.map((Character:Character) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={`${Character.id}-${Character.name}`}>
                  <Card elevation={0} className={classes.root}>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                          <Typography variant="h6" component="h4">
                            {Character.name}
                          </Typography>
                          <Typography variant="body2" component="p">
                            {Character.biography['full-name']}
                          </Typography>
                          <Button
                            variant="contained"
                            className={classes.viewCharacter}
                            onClick={(e) => showCharacter(Character)}
                            startIcon={<PageViewIcon />}>
                            Ver ficha
                          </Button>
                        </CardContent>
                        <div className={classes.cover} >
                          <CardMedia
                            className={classes.coverImg}
                            component="img"
                            image={Character.image.url}
                            title={`Perfil ${Character.name}`} />
                        </div>
                      </div>
                  </Card>
                </Grid>
                )
            })
        }
      </Grid>
    </div>
  )
    } else {
      return (
        <div>
          <div></div>
        </div>
      )
    }
}
export default All;
