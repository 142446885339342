import React from 'react';
import { Container } from '@material-ui/core'

function Layout(props) {
  return (
    <Container max-width="sm">
      {props.children}
    </Container>
  )
}
export default Layout;