import React from 'react';
import './ProfileContent.css';

const CardLabel = (props:any) => {
  if (props.label) {
    return (
      <div className={'Label'}>
        {props.label}
      </div>)
  }
  return null;
}

const CardContent = (props:any) => {
  if (props.children) {
    return (
      <div className={'Value'}>
        {props.children}
      </div>
    )
  }
  return null;
}

export default (props:any) => {
  return (
    <>
      <CardLabel label={props.label} />
      <CardContent children={props.children} />
    </>
  )
}