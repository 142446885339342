import React from 'react';
import {
  Tabs,
  Tab,
} from '@material-ui/core';

const tabProps = (index: any) => {
  return {
    id: `hero-tab-${index}`,
    'aria-controls': `hero-tabpanel-${index}`,
  }
}

export default (props:any) => {
  const {
    activeTab,
    tabs,
    handleChange,
    label,
  } = props;
  return (
    <Tabs value={activeTab} onChange={handleChange} aria-label={label} variant="scrollable">
      {tabs.map((tab, index) => (
          <Tab key={`tab-${index}`} label={tab.label} {...tabProps(index)} />
        ))}
    </Tabs>
  )
}