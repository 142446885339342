import React from 'react';
import CardProfile from '../../CardProfile';
import HeroPowerStats from '../../HeroPowerStats';

export default (props:any) => {
  const { Character } =  props;

  return (
    <>      
      {Object.keys(Character.powerstats).map((power, index) => {
        return (
          <CardProfile key={`${power}-${index}`} label={power}>
            <HeroPowerStats value={Character.powerstats[power]} />
          </CardProfile>
        );
      })}
    </>
  )
}
