import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ProfileContent from './ProfileContent';


const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: { 
      display: 'flex',
      padding: '12px',
      borderBottom: '1px solid #ececec',
      color: '#777',
    }
  })
);

export default (props:any) => { 

  const classes = useStyles();

  return (
  <div className={classes.root}>
    <ProfileContent {...props} />
  </div>
)};