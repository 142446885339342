const config = {
  token: process.env.REACT_APP_API_TOKEN,
  dev: {
    url: 'api/',
  },
  prd: {
    url:  'https://superheroapi.com/api.php/',
  }
}

export default config;