import React from 'react';
import {
  Grid,
  Avatar,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';

import {
  HeroTabs,
  HeroTabsHeader,
  HeroTabsPanel,
} from '../../Tabs';

const useStyles = makeStyles((theme: Theme) => 
createStyles({
  root: {
    '&> .MuiDrawer-paper': {
      width: '90vw',
      background: '#ffffff',
      padding: '1.8rem',
    },
    '&> .MuiDrawer-paper > .MuiCard-root': {
      overflow: 'auto',
      minHeight: '100vh',
      position: 'relative',
    },
  },
  avatarLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  closeButton: {
    position: 'absolute',
    transformOrigin: 'center center',
    right: '10px',
    top: '10px',
    borderRadius: '50%',
    padding: '5px',
    border: '1px solid var(--terminal-second-color)',
    background: 'var(--dark)',
    transition: 'all ease 0.3s',
    '&:hover': {
      transform: 'scale(1.2) rotate(180deg)',
    },
    '& > svg': {
      fill: 'var(--terminal-main-color)',
    },
  },
  srOnly: {
    visibility: 'hidden',
    display: 'inline-block',
    overflow: 'hidden',
    width: '1px',
    height: '1px',
  }
})
);

export default (props:any) => {
  const classes = useStyles();

 const { Character } = props;
  return (
    <Card
    elevation={0}>
    <CardContent>
    <button className={classes.closeButton} onClick={props.hideCharacter}>
      <CloseIcon />
      <span className={classes.srOnly}>
        Fechar
      </span>
    </button>
      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="center">
        <Grid item xs={12} sm={3} md={4} >
          <Avatar
            src={Character.image.url}
            className={classes.avatarLarge} />
        </Grid>
        <Grid item xs={12} sm={9} md={8}>
          <Typography variant="h5">
            {Character.name}
          </Typography>
          <Typography variant="subtitle1" component="h6">
            {Character.biography['full-name']}
          </Typography> 
        </Grid>
        <Grid item xs={12}>
            <HeroTabs>
              <HeroTabsHeader
                tabs={props.heroTabs}
                handleChange={props.handleTabsChange}
                activeTab={props.activeTab}
                label="Hero profile"/>
              <HeroTabsPanel components={props.heroTabs} activeTab={props.activeTab} />
            </HeroTabs>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  )
}