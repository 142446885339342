import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import {
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => 
createStyles({
  statusColor: {
    backgroundColor: 'var(--dark)',
    '& > div.MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'var(--terminal-main-color)',
    },
  },
})
);


export default (props: LinearProgressProps & { value: number }) => {
  const progress = Number(props.value);
  
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} className={classes.statusColor} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" >{`${Math.round(
          Number(progress),
        )}%`}</Typography>
      </Box>
    </Box>
  );
}