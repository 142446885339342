import axios from 'axios';
import config from '../Config/urls';


const getByName = async (name) => {
  try {
    const spheroAPIUrl = process.env.NODE_ENV === 'development' ? config.dev.url : config.prd.url ; 
    const UrlBase = `${spheroAPIUrl}${config.token}/search/${name}`;
    const result = await axios.get(UrlBase).then((res) => {
      return res;
    }).catch(erro => erro);
    
    if (result.status === 200 && result.data.response !== 'error') {
      return {
        ...result.data,
      }
    }

    return {
      name: result.data.name,
      response: result.data.response,
      message: result.data.error
    };

  } catch (error) {
    console.log('Erro: ', error);
    return {
      name: 'Nuclear Blast',
      message: 'Request error.'
    }
  }
}

const CharactersService = {
  getByName,
}
export default CharactersService;