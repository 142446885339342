import React from 'react';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import CardProfile from '../../CardProfile';

export default (props:any) => {
  const { Character } =  props;

  return (
    <>      
      <CardProfile label='Alter egos'>
        {Character.biography['alter-egos']}
      </CardProfile>
      <CardProfile label='Alias(es)' >
        {Character.biography['aliases'].map((alias, i) => (
          <div key={`alias-${i}`}>
            <PermIdentityIcon /> {alias}
          </div>
          ))}
      </CardProfile>
      <CardProfile label='Place of Birth'>
        {Character.biography['place-of-birth']}
      </CardProfile>
      <CardProfile label='First Appearance'>
        {Character.biography['first-appearance'].split(';')
        .map((appearance, i) => {
          return <div key={`first-appearance-${i}`}>{appearance}</div>
        })}
      </CardProfile>
    </>
  )
}