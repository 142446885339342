import React from 'react';
import CardProfile from '../../CardProfile';

export default (props:any) => {
  const { Character } =  props;

  return (
    <>      
      <CardProfile label="Base">
        {Character.work['base']}
      </CardProfile>
      <CardProfile label="Occupation">
        {Character.work['occupation']}
      </CardProfile>
    </>
  )
}
